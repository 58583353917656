<template>
  <div>
    <div id="app-layout" class="container-fluid">
      <router-view></router-view>
    </div>
    <footer class="d-flex mt-5 bg-dark">
      <div class="container-fluid">
        <div class="row">
          <div class="col text-center mt-5">
            <a href="https://truckyapp.com/enterprise" target="_blank"
              ><img class="logo" src="/assets/enterprise-logo.png"
            /></a>
            <br />
            VTC Management Platform - Job Tracking - Realistic Economy
          </div>
        </div>
        <div class="row mt-3 mb-5">
          <div class="col-12 text-center">
            <i class="fa fa-copyright" aria-hidden="true"></i>
            {{ new Date().getFullYear() }} - Made with
            <i class="fa fa-heart" aria-hidden="true"></i> by
            <a href="https://truckyapp.com/" target="_blank">Trucky</a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  mounted: function () {
    this.getUser();
  },
  methods: {
    getUser: async function () {
      const result = await axios.get("/frontend-api/user");
      this.$store.dispatch("setAuth", result.data.user);
    },
  },
};
</script>
