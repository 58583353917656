/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import VueRouter from 'vue-router'
import Layout from './pages/Layout.vue';
import VueI18n from 'vue-i18n';
import Vuex from 'vuex';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

window.Vue = require('vue').default;
window.$ = window.jQuery = window.jquery = require('jquery');

require('clipboard');

const routes = [
    {
        path: '/vtc/:slug',
        component: () => import('./pages/CompanyDetail.vue'),
        name: 'CompanyDetail'
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(Vuex);
Vue.use(VueToast);

const i18n = new VueI18n({
});

const store = new Vuex.Store({
    state: {
        user: null,
        pageTitle: 'Trucky Enterprise'
    },
    mutations: {
        setUser(state) {

        }
    },
    actions: {
        setAuth({ state }, user) {
            state.user = user;
        },
        changePageTitle({ state }, pageTitle) {
            state.pageTitle = pageTitle;
            document.title = `${pageTitle} - Trucky VTC Hub`;
        }
    }
})

const app = new Vue({
    store,
    i18n,
    router,
    components: {
        Layout
    }
}).$mount('#app');
